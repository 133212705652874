.faculties-logistics {
  @include phones {
    height: 71rem;
  }

  &::before {
    @include tablets {
      background-size: 50%;
      bottom: 10rem;
      right: -10rem;
    }

    @include phones {
      background-size: 60%;
      right: -5rem;
      bottom: 15rem;
    }
  }
}