/***********/
/* Common */
@import 'common/_variables';
/***********/

/* popup */
@import 'popup/_responsive';

/***********/
/* Blocks */
@import 'banner/_responsive';
@import 'neuronet/_responsive';
@import 'head/_responsive';
@import 'foot/_responsive';
@import 'hello/_responsive';
@import 'edu/_responsive';
@import 'directions/_responsive';
@import 'about/_responsive';
@import 'partners/_responsive';
@import 'news/_responsive';
@import 'links/_responsive';
@import 'form/_responsive';
@import 'fix-menu/_responsive';
@import 'breadcrumbs/_responsive';
@import 'fix-menu-intelligence/_responsive';
@import 'nav/program/blocks/program-edu/_responsive';
@import 'nav/program/blocks/form/_responsive';

@import 'nav/cards/entrepreneurship/blocks/faculties/_responsive';
@import 'nav/cards/entrepreneurship/blocks/disciplines/_responsive';
@import 'nav/cards/entrepreneurship/blocks/become/_responsive';
@import 'nav/cards/entrepreneurship/blocks/exams/_responsive';
@import 'nav/cards/entrepreneurship/blocks/documents/_responsive';

@import 'nav/cards/finance/blocks/faculties/_responsive';

@import 'nav/cards/hotel/blocks/faculties/_responsive';

@import 'nav/cards/economy/blocks/faculties/_responsive';

@import 'nav/cards/personnel-management/blocks/faculties/_responsive';

@import 'nav/cards/municipal-government/blocks/faculties/_responsive';

@import 'nav/cards/project-management/blocks/faculties/_responsive';

@import 'nav/cards/civil-law/blocks/faculties/_responsive';

@import 'nav/cards/banking/blocks/faculties/_responsive';

@import 'nav/cards/criminal-law/blocks/faculties/_responsive';

@import 'nav/cards/trading-business/blocks/faculties/_responsive';

@import 'nav/cards/hotel-service/blocks/faculties/_responsive';

@import 'nav/cards/information-systems/blocks/faculties/_responsive';

@import 'nav/cards/design/blocks/faculties/_responsive';

@import 'nav/cards/economics-accounting/blocks/faculties/_responsive';

@import 'nav/cards/commerce-by-industry/blocks/faculties/_responsive';

@import 'nav/cards/law-organization/blocks/faculties/_responsive';

@import 'nav/cards/logistics/blocks/faculties/_responsive';

@import 'nav/cards/healthcare/blocks/faculties/_responsive';

@import 'nav/cards/sports-management/blocks/faculties/_responsive';

@import 'nav/cards/marketing/blocks/faculties/_responsive';

@import 'nav/cards/taxation/blocks/faculties/_responsive';

@import 'nav/contacts/blocks/contacts/_responsive';
@import 'nav/partners/blocks/college/_responsive';
@import 'nav/partners/blocks/univer/_responsive';
@import 'nav/partners/blocks/educational/_responsive';
@import 'nav/partners/blocks/acting/_responsive';
@import 'nav/partners/blocks/agents/_responsive';
@import 'nav/partners/blocks/cooperation/_responsive';
@import 'nav/partners/blocks/form-partners/_responsive';
@import 'nav/about/blocks/about-academy/_responsive';
@import 'nav/about/blocks/history/_responsive';
@import 'nav/about/blocks/founder/_responsive';
@import 'nav/about/blocks/acting-about/_responsive';
@import 'nav/applicants/blocks/entrant/_responsive';
@import 'nav/applicants/blocks/reception/_responsive';
@import 'nav/applicants/blocks/admission/_responsive';
@import 'nav/applicants/blocks/proceed/_responsive';
@import 'nav/applicants/blocks/dormitory/_responsive';
@import 'fix-menu-students/_responsive';
@import 'nav/students/spa/blocks/spa/_responsive';
@import 'nav/students/timetable/blocks/timetable/_responsive';
@import 'nav/students/services/blocks/paid/_responsive';
@import 'nav/students/council/blocks/council/_responsive';
@import 'nav/students/library/blocks/library/_responsive';

@import 'nav/students/dormitory/blocks/dormitory/_responsive';

@import 'nav/students/employment/blocks/employment/_responsive';

@import 'nav/students/employment/blocks/why/_responsive';

@import 'nav/students/employment/blocks/vacancy/_responsive';

@import 'nav/students/employment/blocks/information/_responsive';

@import 'nav/sveden/paid_edu/blocks/intelligence/_responsive';

@import 'nav/sveden/paid_edu/blocks/form/_responsive';

@import 'nav/sveden/common/blocks/top_page_menu/_responsive';

@import 'nav/sveden/common/blocks/basic/_responsive';

@import 'nav/sveden/common/blocks/form/_responsive';

@import 'nav/sveden/common/blocks/simplebar/_responsive';

@import 'nav/sveden/document/blocks/docs/_responsive';

@import 'nav/sveden/document/blocks/form/_responsive';

@import 'nav/sveden/eduStandarts/blocks/standards/_responsive';

@import 'nav/edu-foreigner/blocks/ru/edu-foreigner/_responsive';

@import 'nav/edu-foreigner/blocks/ru/foreigner-desc/_responsive';

@import 'nav/edu-foreigner/blocks/ru/advantages/_responsive';

@import 'nav/edu-foreigner/blocks/ru/questions/_responsive';

@import 'nav/science/blocks/events/_responsive';
@import 'nav/science/blocks/event/_responsive';
/***********/