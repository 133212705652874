.partners {
  &__head {
    @include tablets {
      margin-bottom: 2.7rem;
    }
  }

  &__list {
    @include tablets {
      margin-bottom: 8.5rem;
    }

    @include phones {
      // flex-wrap: wrap;
      // justify-content: space-between;
      margin-bottom: 1.6rem;
    }
  }

  &__item {
    @include tablets {
      width: 16.2rem;
      height: 10rem;
    }

    @include phones {
      width: 16rem;
      // margin-right: 0;
    }

    // &:nth-child(2) {
    //   @include phones {
    //     margin-right: 0;
    //   }
    // }
  }

  &__desc {
    @include tablets {
      font-size: 5.6rem;
    }

    @include phones {
      font-size: 3.8rem;
    }
  }
}

.arrow {
  @include phones {
    display: none;
  }

  &_mobile {
    @include phones {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8rem;
    }
  }
}

.bx-viewport {
  @include phones {
    width: 100% !important;
    display: flex;
  }
}
