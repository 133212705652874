.library {
  @include phones {
    padding-bottom: 6rem;
  }

  &__left {
    @include tablets {
      width: 100%;
    }
  }

  &__title {
    @include tablets {
      font-family: "PF Din Text Comp Pro";
      font-size: 4.8rem;
      margin-bottom: 2rem;
    }

    @include phones {
      font-size: 3rem;
      margin-bottom: 0.8rem;
    }
  }

  &__subtitle {
    @include tablets {
      font-size: 1.8rem;
      width: 100%;
      margin-bottom: 2rem;
    }

    @include phones {
      font-size: 1.4rem;
    }
  }

  &__tasks {
    @include tablets {
      padding: 4rem;
    }
  }

  &__tasks-title {
    @include tablets {
      margin-bottom: 2rem;
    }
  }

  &__tasks-subtitle {
    @include tablets {
      margin-bottom: 2rem;
    }

    @include phones {
      font-size: 1.6rem;
    }
  }

  &__tasks-desc {
    @include tablets {
      margin-bottom: 2rem;
      font-size: 1.6rem;
    }

    @include phones {
      font-size: 1.4rem;
      width: 99%;
    }
  }

  &__right {
    @include tablets {
      display: none;
    }
  }
}
