.documents {
  @include tablets {
    margin-bottom: -2rem;
  }

  &__title {
    @include tablets {
      font-size: 3rem;
      margin-bottom: 2.8rem;
    }
  }

  &__list {
    @include tablets {
      width: 80%;
    }

    @include phones {
      flex-direction: column;
      width: 100%;
      margin-bottom: 6rem;
    }
  }

  &__item {
    @include tablets {
      justify-content: normal;
    }

    @include phones {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2.8rem;
      height: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__img {
    @include tablets {
      margin-bottom: 2.8rem;
    }
  }
  &__name {
    @include tablets {
      margin-bottom: 0.8rem;
      width: 80%;
    }
  }

  &__desc {
    @include tablets {
      width: 75%;
    }

    @include phones {
      width: 100%;
    }
  }
}
