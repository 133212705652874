.about {
  @include tablets {
    min-height: 76.8rem;
  }
  &::before {
    @include tablets {
      display: none;
    }

    @include phones {
      display: block;
      background: url(/img/about/about.svg) right top/50% no-repeat;
      top: 4rem;
      right: -3rem;
    }
  }

  &__title {
    @include tablets {
      font-size: 3.8rem;
    }

    @include phones {
      padding: 5rem 0 3rem;
    }
  }

  &__info {
    @include phones {
      flex-wrap: wrap;
      margin-bottom: 3rem;
    }
  }

  &__desc {
    @include tablets {
      width: 19rem;
    }

    @include phones {
      flex-direction: column;
      align-items: flex-start;
      width: 12rem;

      &:nth-child(1) {
        flex: 1 0 100%;

        @include phones {
          margin-bottom: 2rem;
        }
      }

      &:nth-child(2) {
        flex: 2;
      }

      &:nth-child(3) {
        flex: 3;
      }
    }
  }

  &__num {
    @include tablets {
      font-size: 5.4rem;
      margin-right: 0.8rem;
    }
  }

  &__text {
    @include tablets {
      font-size: 1.6rem;
    }
  }
}

.list {
  @include phones {
    justify-content: center;
  }

  &__item {
    @include tablets {
      width: 22.2rem;
      height: 19rem;
    }

    @include phones {
      width: 33rem;
      height: 8.1rem;
      padding: 0;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
  }
  &__text {
    @include phones {
      width: 21rem;
    }
  }
}
