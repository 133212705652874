.program-edu {
  @include tablets {
    margin-bottom: 8rem;
  }

  &__title {
    @include tablets {
      font-family: "PF Din Text Comp Pro";
      font-size: 3.8rem;
    }
  }

  &__checkbox {
    @include phones {
      display: none;
    }
  }

  &__right {
    @include tablets {
      width: 65%;
    }

    @include phones {
      width: 100%;
    }
  }

  &__right-head {
    @include phones {
      flex-direction: column;
    }
  }

  &__head {
    @include phones {
      display: flex;
      justify-content: space-between;
    }
  }

  &__item {
    @include tablets {
      display: none;
      width: 22.3rem;
      height: 18.4rem;
      // &:nth-child(-n + 6) {
      //   display: flex;
      // }
    }
    @include phones {
      width: 15.8rem;
      height: 15.1rem;
    }
  }

  &__desc {
    @include phones {
      font-size: 1.3rem;
    }
  }
}

.radio {
  &__visible {
    @include phones {
      margin-right: 0;
    }
  }
}

.controls {
  &__item {
    &:last-child {
      @include phones {
        margin-bottom: 1.2rem;
      }
    }
  }
}

.select {
  @include phones {
    width: 15.8rem;
  }
}

.new-select {
  &__list {
    @include phones {
      font-size: 1.2rem;
    }
  }
}

.filter {
  @include phones {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }
}
