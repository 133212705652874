.intelligence {
  @include phones {
    padding-top: 4rem;
  }

  &__body {
    @include phones {
      padding-bottom: 6rem;
    }
  }

  &__left {
    @include tablets {
      width: 100%;
    }
  }

  &__right {
    @include tablets {
      display: none;
    }
  }

  &__title {
    @include phones {
      font-size: 3rem;
      margin-bottom: 3rem;
    }
  }

  &__subtitle {
    @include phones {
      font-size: 1.4rem;
      margin-bottom: 4.8rem;
    }
  }

  &__desc {
    @include phones {
      font-size: 1.4rem;
    }
  }
}
