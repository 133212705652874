.acting {
  &_about {
    @include tablets {
      margin-bottom: -2rem;
    }
  }

  &__top_about {
    @include phones {
      flex-direction: column;
      justify-content: normal;
      margin-bottom: 3.7rem;
    }
  }

  &__title_about {
    @include phones {
      margin-bottom: 1.1rem;
    }
  }

  &__item {
    &_about {
      @include phones {
        width: 27.8rem;
        flex-direction: row-reverse;
        align-items: center;
        padding: 1.1rem 1.9rem 1.1rem 1.1rem;
        margin-bottom: 0;
      }
    }

    &-text_about {
      @include phones {
        font-size: 1.4rem;
        margin-right: 0;
      }
    }

    &-img_about {
      @include phones {
        min-width: 3.8rem;
        height: 3.8rem;
        margin-right: 1.4rem;
      }
    }
  }
}
