.head {
  @include phones {
    border-bottom: 0.1rem solid #e4e7eb;
  }

  &__for-visually {
    @include tablets {
      margin-bottom: 1.1rem;
      height: 4rem;
    }

    @include phones {
      display: none;
    }
  }

  &__vis {
    @include tablets {
      letter-spacing: -0.01rem;
    }
  }

  &__vis-eye {
    &::before {
      @include tablets {
        left: -2.1rem;
      }
    }
  }

  &__contacts {
    @include tablets {
      margin-bottom: 1rem;
    }

    @include phones {
      margin-bottom: 0;
      padding: 0.7rem 0 0.9rem;
      align-items: center;
    }
  }

  &__nav {
    @include phones {
      display: none;
    }

    &-fix {
      @include tablets {
        display: none;
      }
    }
  }

  &__right {
    @include tablets {
      margin-left: auto;
    }
  }

  &__social {
    @media(max-width: 768px) {
      a {
        width: 2.6rem;
        height: 2.6rem;
        &:last-child {
          display: flex;
        }
      }
      a + a {
        margin-left: 1rem;
      }
    }
  }

  &__time {
    margin-left: 1rem;
    @media(max-width: 992px) {
      display: none;
    }
  }

  &__link {
    @include tablets {
      font-size: 1.2rem;
    }
  }
}

.contacts__phone {
  @media (max-width: 992px) {
    font-size: 1.2rem;
  }
  @include tablets {
    display: none;
  }

  &:last-child {
    @include tablets {
      margin-right: 1rem;
    }
  }
}

.contacts {
  white-space: nowrap;
  @media (max-width: 992px) {
    margin-right: 1rem;
  }
  @include tablets {
    margin-right: 0;
  }

  &__phone {
    @include tablets {
      font-size: 1.2rem;
    }
  }
}

.head .button {
  @include desktop {
    font-size: 1.4rem;
    width: 17rem;
  }
  @media(max-width: 678px) {
    display: none;
  }
}

.logo {
  @media (max-width:992px) {
  max-width: 25rem;
  @include tablets {
    max-width: 15rem;
    margin-right: 1rem;
  }
  &__icon {
    @include tablets {
      display: none;
    }

    &-tablets {
      @include tablets {
        display: block;
      }

      @include phones {
        display: none;
      }
    }

    &-mobile {
      @include phones {
        display: block;
      }
    }
    }
  }
}

.hamburger {
  @media(max-width: 678px) {
    display: flex;
  }
  @include phones {
    width: 2.6rem;
    height: 2.6rem;
  }
}
