.form {
  @include tablets {
    min-height: 34rem;
    padding: 4rem 4.4rem 2.4rem 3rem;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    justify-content: space-between;
    flex-direction: row;
    &::after {
      content: "";
      background: url(/img/form/leaf.svg) center/50rem no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 50rem;
      height: 100%;
      border-radius: 2rem;

      @include phones {
        display: none;
      }
    }
  }

  @include phones {
    display: block;
    height: 44.6rem;
    flex-direction: column;
    padding: 2.5rem 1.4rem 4rem 1.6rem;
  }

  &__title {
    @include tablets {
      font-size: 2.4rem;
      /* width: 50%; */
      margin-bottom: 0;
      flex: 1 0 36%;
      padding-right: 2rem;
    }

    @include phones {
      font-size: 2rem;
      flex: 0;
      padding: 0;
      margin-bottom: 2rem;
    }
  }

  &__row {
    @include tablets {
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0;
      flex: 3;
    }

    @include phones {
      flex: 0;
      justify-content: normal;
    }
  }

  &__item {
    @include tablets {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0.8rem;
    }

    @include phones {
      margin-bottom: 1.2rem;
    }
  }

  &__input {
    @include tablets {
      height: auto;
      padding: 1.6rem;
    }
  }
  &__text {
    @include tablets {
      display: none;
    }
    &-tablets {
      @include tablets {
        display: block;
        width: 100%;
        color: #fff;
        font-size: 1.2rem;
      }
    }
  }
  
  &-success{
    @include tablets {
      padding: 4rem 4.4rem 2.4rem 3rem;
      }
    @include phones {
      padding: 2.5rem 1.4rem 4rem 1.6rem;
    }
    &__text{
      @include tablets {
        font-size: 3.4rem;
        }
      @include phones {
        font-size: 2.2rem;
      }
    }
  }
}
