.dormitory {
  @include tablets {
    margin-bottom: -2rem;
  }

  &__desc {
    @include tablets {
      width: 100%;
      margin-bottom: 8rem;
    }
    @include phones {
      margin-bottom: 6rem;
    }
  }

  &__title {
    @include tablets {
      margin-bottom: 5rem;
    }

    @include phones {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }

  &__subtitle {
    @include tablets {
      width: 100%;
      margin-bottom: 3.2rem;
    }

    @include phones {
      font-size: 1.8rem;
      margin-bottom: 2.4rem;
    }
  }

  &__list {
    @include phones {
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      width: 100vw;
      margin-left: calc(-50vw + 50%);
    }
  }

  &__item {
    @include tablets {
      padding: 4.1rem 2rem;
    }

    @include phones {
      scroll-snap-align: start;
      scroll-margin-left: 1.5rem;
      padding: 4.1rem 4.8rem;
    }
    &:first-child {
      @include phones {
        margin-left: 1.5rem;
      }
    }
  }

  &__item-title {
    @include tablets {
      margin-bottom: 2rem;
    }
  }

  &__icon {
    @include tablets {
      height: 16rem;
    }
  }

  &__subitem {
    @include tablets {
      width: 100%;
      padding: 1.6rem 2.4rem;
    }

    &:nth-child(5) {
      @include tablets {
        margin-bottom: 1.6rem;
      }
    }
  }

  &__subitem-text {
    @include tablets {
      font-size: 1.8rem;
      font-weight: 400;
    }

    @include phones {
      font-size: 1.6rem;
      line-height: 1.3;
    }
  }
}
