.lang-advantages {
    @include phones {
        margin-bottom: 6rem;
    }

    &__title {
        @include phones {
            font-size: 2.8rem;
            margin-bottom: 2rem;
        }
    }

    &__item {
        &-title {
            @include phones {
                font-size: 1.4rem;
            }
        }

        &-desc {
            @include phones {
                font-size: 1.4rem;
            }
        }
    }
}