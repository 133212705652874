.acting {
  &__title {
    @include tablets {
      font-size: 3rem;
    }
  }

  &__employers-title {
    @include tablets {
      font-size: 2rem;
    }
  }

  &__employers {
    &-item-title {
      @include tablets {
        margin-right: 2.5rem;
        font-size: 1.6rem;
      }

      @include phones {
        border: 0.2rem solid #a0a2a699;
        border-radius: 1rem;
        color: #6e7175;
        padding: 0.5rem;
        white-space: nowrap;
        scroll-snap-align: start;
        font-size: 1.6rem;
        margin-right: 1.2rem;
      }
      &_active {
        @include phones {
          border: 0.2rem solid #43c375;
          border-radius: 1rem;
          padding: 0.5rem;
          color: #212121;
        }
      }
    }
  }
  &__employers-item {
    @include tablets {
      width: 16.2rem;
      height: 10rem;
    }

    @include phones {
      display: none;
      &:nth-child(-n + 6) {
        display: flex;
      }
    }
  }
}
