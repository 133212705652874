.menu {
  &_students {
    @include tablets {
      display: block;
    }

    @include phones {
      bottom: calc(100% - 5rem);
    }
  }

  &__fix {
    &_students {
      @include phones {
        height: 42rem;
      }
    }
  }

  &__btn {
    &_students {
      @include phones {
        height: 42rem;
      }
    }
  }
}
