.dormitory {
  &_students {
    @include phones {
      padding-bottom: 2rem;
    }
  }

  &__desc {
    &_students {
      @include tablets {
        width: 100%;
      }
    }
  }

  &__title {
    &_students {
      @include phones {
        font-size: 3rem;
        margin-bottom: 2rem;
      }
    }
  }

  &__subtitle {
    &_students {
      @include phones {
        font-size: 1.4rem;
        line-height: 1.27;
      }
    }
  }

  &__item {
    &_students {
      @include tablets {
        width: 100%;
        margin-right: 2rem;
      }
    }
  }

  &__item-title {
    &_students {
      @include phones {
        white-space: nowrap;
      }
    }
  }

  &__menu {
    &_students {
      @include tablets {
        display: none;
      }
    }
  }
}
