.founder {
  @include tablets {
    padding: 6.5rem 0 7.9rem;
  }

  &__top {
    @include phones {
      flex-direction: column;
    }
  }

  &__left {
    @include tablets {
      margin-right: 3.2rem;
    }

    @include phones {
      margin-right: 0;
    }
  }

  &__right {
    @include tablets {
      width: 100%;
    }
  }

  &__title {
    @include tablets {
      font-size: 3rem;
    }
  }

  &__photo {
    @include phones {
      display: none;
    }

    &_mobile {
      @include phones {
        display: block;
        width: 100%;
      }
    }
  }

  &__subtitle {
    @include tablets {
      font-size: 2rem;
      margin-bottom: 3.1rem;
    }
  }

  &__desc-text {
    @include tablets {
      font-size: 1.4rem;
      margin-bottom: 4rem;
    }

    &-italic {
      @include tablets {
        margin-bottom: 2.8rem;
        font-size: 1.2rem;
      }
    }
  }

  &__bottom {
    &:before {
      @include phones {
        left: auto;
      }
    }
  }

  &__feedback {
    @include tablets {
      padding: 7.4rem 5.9rem 6.1rem 6.2rem;
    }

    @include phones {
      padding: 4rem 1.9rem 4.2rem 2.1rem;
    }

    &-text {
      @include tablets {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
      }
    }

    &-top {
      @include tablets {
        font-size: 1.8rem;
        width: 92%;
        margin-bottom: 2.4rem;
      }
    }
  }
}
