.vacancy {

  @include tablets {

    padding-bottom: 5rem;

    &__title {
      font-size: 3rem;
      margin-bottom: 3rem;
    }

    &__tabsblock {
      margin-bottom: 2rem;
    }
  
    &__tabs {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 1rem 0;
  
      &-title {
        padding-left: 0;
        font-weight: 600;
        font-size: 1.6rem;
  
        &.current {
            color: #6e7175;
  
            &:before {
              display: none;
          }
        }
      }
    }
  
    &__tabsbox {
      display: none;
  
      &.visible {
        display: none;
      }
    }

    &__info {
      padding: 1rem;
      border-radius: 1rem;
      font-size: 1.4rem;
      margin: 0;
    }

  }


  @include phones {

    padding-bottom: 6rem;

    &__title {
      font-size: 3rem;
      margin-bottom: 3rem;
    }

    
  }

}
