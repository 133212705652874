.proceed {
  @include phones {
    margin-bottom: 6rem;
  }

  &__title {
    @include phones {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }

  &__steps-table {
    @include phones {
      margin-bottom: 6rem;
    }
  }

  &__steps {
    @include tablets {
      width: 100%;
    }
  }

  &__step-title {
    @include phones {
      font-size: 1.8rem;
    }
  }

  &__step-subtitle {
    @include phones {
      font-size: 1.8rem;
    }
  }

  &__step-item {
    @include phones {
      font-size: 1.4rem;
    }
  }

  &__icon {
    @include phones {
      margin-right: 2rem;
    }
  }

  &__desc {
    @include phones {
      font-size: 1.4rem;
    }
  }
}
