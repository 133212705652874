.edu {
  @include tablets {
    margin-bottom: 7rem;
    margin-top: -17rem;
  }

  @include phones {
    height: auto;
    padding-top: 3rem;
    margin-top: 0;
    margin-bottom: 6rem;
  }

  &::before {
    @include tablets {
      background: url(/img/edu/edu-mob.png) right center/44% no-repeat;
      top: -2rem;
      right: 3rem;
    }

    @include phones {
      display: none;
    }
  }

  &__title {
    @include tablets {
      font-size: 3.8rem;
      margin-bottom: 3rem;
    }

    @include phones {
      font-size: 3.8rem;
      margin-bottom: 2.6rem;
    }
  }

  &__desc {
    @include tablets {
      width: 94%;
      align-items: flex-end;
    }

    @include phones {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__list {
    @include phones {
      display: flex;
      overflow-y: auto;
      scroll-snap-type: x mandatory;
      width: 100vw;
    }
  }

  &__item {
    @include tablets {
      margin-bottom: 1.6rem;
    }

    @include phones {
      border: 0.2rem solid #a0a2a699;
      height: 3.7rem;
      border-radius: 0.5rem;
      margin-right: 1rem;
      scroll-snap-align: start;
      padding: 0.6rem 1rem;
      scroll-snap-align: start;
      font-size: 1.8rem;

      &:first-child {
        margin-left: 1.5rem;
      }
    }
  }

  &__list {
    @include tablets {
      width: 50%;
      font-size: 1.8rem;
    }

    @include phones {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      text-align: center;
      white-space: nowrap;
    }
  }

  &__text {
    @include tablets {
      width: 33.8rem;
      min-height: 24rem;
      padding: 4rem 2rem 4.4rem 3.4rem;
      font-size: 1.6rem;
      line-height: 1.3;
      margin-bottom: 2rem;
      /* word-break: break-word; */
    }

    @include phones {
      width: 100%;
      margin-bottom: -2rem;
      padding: 4rem 4rem 4.4rem 4.4rem;
      line-height: 1.37;
    }
  }

  &__photo {
    @include phones {
      width: 100%;
      display: block;
      z-index: 1;
    }
  }
}

.active-edu {
  @include phones {
    border: 0.2rem solid #32a05d;
    font-size: 1.8rem;
  }

  &:before {
    @include phones {
      display: none;
    }
  }
}
