.educational {

    &__title {
      @include tablets {
        font-size: 2rem;
        margin-bottom: 2.8rem;
      }
    }

    &__links {
      @include tablets {
        font-size: 1.4rem;
      }

    }
  }
  