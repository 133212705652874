.timetable {
  &__body {
    @include tablets {
      display: block;
      justify-content: unset;
    }
  }

  &__left {
    @include tablets {
      width: 100%;
    }
  }

  &__right {
    @include tablets {
      display: none;
    }
  }

  &__list {
    @include tablets {
      display: flex;
      justify-content: space-between;
    }

    @include phones {
      display: -webkit-box;
      overflow-x: auto;
      scroll-snap-type: x;
    }
  }

  &__item {
    @include tablets {
      margin-right: 0;
    }
  }

  &__btn {
    @include phones {
      align-items: center;
    }
  }

  &__download-text {
    @include phones {
      text-align: center;
    }
  }

  &__download-btn {
    @include phones {
      white-space: nowrap;
    }
  }

  &__occupations {
    &-wrap {
      @include tablets {
        flex-wrap: wrap;
      }
      @include phones {
        display: none;
      }
      &_mobile {
        @include phones {
          display: flex;
        }
      }
    }
    &-list {
      @include phones {
        width: 50%;
      }
    }
  }
}
