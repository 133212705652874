.directions {
  &__head {
    @include tablets {
      margin-bottom: 4.2rem;
    }

    @include phones {
      margin-bottom: 2.2rem;
    }
  }

  &__list {
    @include phones {
      justify-content: center;
      max-width: 100%;
    }
  }

  &__more {
    @include phones {
      margin-bottom: 6rem;
    }
  }

  &__title {
    @include tablets {
      font-size: 3.8rem;
      margin-bottom: 2.8rem;
    }

    @include phones {
      margin-bottom: 2.4rem;
    }
  }

  &__link {
    @include phones {
      justify-content: space-between;
    }
  }

  &__edu {
    @include tablets {
      margin-right: 5rem;
      font-size: 1.8rem;
    }

    @include phones {
      margin-right: 0;
      flex: 0;
      height: 100%;
    }
  }

  &__item {
    @include tablets {
      width: 49%;
    }

    @include phones {
      width: 100%;
      height: 15.6rem;
      padding: 2.1rem 0 2rem 1.6rem;
      margin-bottom: 1rem;
    }
  }

  &__spec {
    @include tablets {
      font-size: 1.6rem;
      line-height: 1.25;
    }
  }
}

.form-directions {
  @include tablets {
    display: none;
  }
}
