.form-docs {
  @include tablets {
    padding: 8rem 0 0;
    margin-bottom: -2rem;
  }

  @include phones {
    padding-top: 6rem;
  }
}
