.contacts-academy {
  @include tablets {
    margin-bottom: 0;
  }
  &__body {
    @include tablets {
      margin-bottom: 5.6rem;
    }
  }

  &__left {
    @include tablets {
      margin-bottom: 4.2rem;
    }

    @include phones {
      flex-direction: column;
    }
  }

  &__head {
    @include tablets {
      width: 40%;
    }

    @include phones {
      width: 100%;
    }
  }
  &__map {
    @include phones {
      width: 100%;
    }
  }
  &__title {
    @include tablets {
      font-size: 3.8rem;
    }
  }
  &__adress {
    @include tablets {
      margin-bottom: 0;
    }
  }

  &__street {
    @include tablets {
      font-size: 2rem;
    }

    @include phones {
      width: 55%;
    }
  }

  &__way {
    @include tablets {
      display: none;
    }
    &_mobile {
      @include tablets {
        display: block;
        margin-bottom: 4.2rem;
      }
    }
  }

  &__right {
    @include phones {
      flex-direction: column;
    }
  }
  &__support {
    @include tablets {
      width: 50%;
    }

    @include phones {
      width: 100%;
      margin-bottom: 3.6rem;
    }
  }
  &__list {
    @include phones {
      width: 100%;
    }
  }

  &__item {
    @include tablets {
      margin-bottom: 3.2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.form-contacts {
  @include tablets {
    margin-bottom: -2rem;
  }
}
