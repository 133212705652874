.disciplines {
  @include tablets {
    padding-top: 35.5rem;
  }

  @include phones {
    padding-top: 27.5rem;
  }

  &__text {
    @include tablets {
      font-size: 2rem;
      line-height: 1.6;
      padding-left: 2.6rem;
    }

    @include phones {
      font-size: 1.8rem;
      line-height: 1.55;
      width: 85%;
    }
  }

  &__content {
    @include tablets {
      flex-direction: column;
    }
  }

  &__title {
    @include tablets {
      margin-bottom: 2.8rem;
      font-size: 3rem;
      width: 100%;
    }
  }

  &__wrapper {
    @include tablets {
      width: 100%;
    }
  }

  &__list {
    @include phones {
      padding: 0 0 0 2.3rem;
      width: 75%;
    }
  }

  &__item {
    @include phones {
      margin-bottom: 1.2rem;
      line-height: 1.7;
      font-size: 1.4rem;
      padding: 0 0 0 2rem;
    }

    &::before {
      @include phones {
        height: 0.6rem;
        top: 1rem;
      }
    }
  }

  &__top {
    @include phones {
      padding: 2rem 2rem 2rem 2.3rem;
    }
  }

  &__top-title {
    @include phones {
      font-size: 1.8rem;
      width: 65%;
    }
  }
}
