.popup {
    @include tablets {
        .form {
            display: block;
            &__button {
                font-size: 1.6rem;
                width: 100%;
                margin-bottom: 1rem;
            }
        }
    }
}