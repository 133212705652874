.basic {

  &__left {
    @include tablets {
      display: table;
      max-width: fit-content;
      width: 100%;
    }
  }

  &__title {
    @include tablets {
      margin-bottom: 2.8rem;
    }

    @include phones {
      font-size: 3rem;
      line-height: 1.1;
      margin-bottom: 2rem;
    }
  }

  &__list {
    @include phones {
      flex-direction: column;
    }

    @include phones {
      margin-bottom: 3.2rem;
    }
  }

  &__item {
    @include phones {
      margin-right: 0;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__top {
    @include phones {
      line-height: 1.56;
      font-size: 1.6rem;
      margin-bottom: 0;
    }
  }

  &__bottom {
    @include phones {
      font-size: 1.4rem;
      line-height: 1.57;
    }
  }

  &__desc {
    @include phones {
      font-size: 1.2rem;
      line-height: 1.66;
      margin-bottom: 4rem;
    }
  }

}
