.history {
  &__desc {
    @include tablets {
      margin-bottom: 4rem;
      flex-direction: column;
    }
    &-left {
      @include tablets {
        width: 65%;
        margin-right: 0;
        margin-bottom: 2.2rem;
        font-size: 1.8rem;
      }

      @include phones {
        width: 100%;
      }
    }

    &-right {
      @include tablets {
        width: 75%;
      }

      @include phones {
        width: 100%;
      }
    }
  }

  &-academy {
    @include tablets {
      flex-direction: column;
    }

    @include phones {
      padding: 3.5rem 1.6rem 4.7rem 2rem;
      margin-bottom: 7rem;
    }

    &:after {
      @include tablets {
        bottom: 0;
        left: auto;
        right: 2rem;
        top: -5rem;
        transform: rotate(11deg);
        width: 18rem;
        height: 25rem;
      }

      @include phones {
        right: 1rem;
        top: -1rem;
        width: 13rem;
        height: 17rem;
      }
    }
  }

  &__title {
    @include tablets {
      font-size: 3rem;
      margin-bottom: 2.2rem;
      margin-right: 0;
    }

    @include phones {
      width: 50%;
    }
  }

  &__subtitle {
    @include tablets {
      font-size: 2rem;
      width: 65%;
    }
  }

  &__info {
    @include phones {
      flex-direction: column;
      margin-bottom: 3rem;
    }

    &-left {
      @include tablets {
        width: 100%;
        margin-right: 4rem;
        font-size: 2rem;
      }

      @include phones {
        font-size: 1.8rem;
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }

    &-right {
      @include tablets {
        width: 100%;
      }

      &-text {
        @include phones {
          width: 100%;
        }
        &-bold {
          @include tablets {
            font-size: 1.8rem;
            width: 100%;
          }

          @include phones {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  &__list {
    @include tablets {
      flex-direction: column;
    }

    @include phones {
      margin-bottom: 6rem;
    }
  }

  &__item {
    @include tablets {
      width: 100%;
    }

    @include phones {
      padding: 1.2rem 2.5rem 1.3rem 1.2rem;
      margin-bottom: 1.2rem;
    }

    &-img {
      @include phones {
        margin-right: 1.2rem;
      }
    }
    &-text {
      @include phones {
        font-size: 1.4rem;
      }
    }
  }
}
