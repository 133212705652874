.council {
  @include phones {
    padding-bottom: 5rem;
  }

  &__left {
    @include tablets {
      width: 100%;
    }
  }

  &__right {
    @include tablets {
      display: none;
    }
  }

  &__title {
    @include phones {
      font-size: 3rem;
      letter-spacing: 0.04rem;
      margin-bottom: 2.4rem;
    }
  }

  &__subtitle {
    @include phones {
      font-size: 1.4rem;
      line-height: 1.3;
      margin-bottom: 3rem;
    }
  }

  &__goals {
    @include phones {
      margin-bottom: 2rem;
    }
  }

  &__line {
    @include phones {
      margin-right: 4.1rem;
    }
  }

  &__desc {
    @include phones {
      width: 100%;
    }

    &-title {
      @include phones {
        font-size: 1.4rem;
        margin-bottom: 2.4rem;
      }
    }

    &-text {
      @include phones {
        margin-bottom: 2.4rem;
        line-height: 1.6;
      }
    }
  }

  &__tasks {
    &-title {
      @include phones {
        line-height: 1.1;
      }
    }

    &-subtitle {
      @include phones {
        font-size: 1.6rem;
        margin-bottom: 2.4rem;
      }
    }

    &-desc {
      @include tablets {
        width: 95%;
      }

      @include phones {
        margin-bottom: 3rem;
      }
    }

    &-text {
      @include phones {
        line-height: 1.57;
      }
    }
  }
}
