.faculties {
  @include phones {
    height: 71rem;
  }

  &::before {
    @include tablets {
      background-size: 50%;
      bottom: 2rem;
      right: -10rem;
    }

    @include phones {
      background-size: 60%;
      right: -5rem;
      bottom: 19rem;
    }
  }

  &__edu {
    @include phones {
      width: 17.2rem;
      padding: 0.8rem 1.2rem;
      font-size: 1.4rem;
    }
  }

  &__name {
    @include tablets {
      font-size: 5.4rem;
    }

    @include phones {
      font-size: 4rem;
      margin-bottom: 3.2rem;
    }
  }

  &__desc {
    @include tablets {
      flex-direction: column;
    }

    @include phones {
      flex-direction: column;
      margin-bottom: 3.2rem;
    }
  }
  &__info {
    @include tablets {
      align-items: center;
      margin-bottom: 1.3rem;
    }
  }

  &__line {
    @include tablets {
      background: #e91e63;
      height: 0.8rem;
      width: 0.8rem;
    }
  }

  &__text {
    @include phones {
      font-weight: 400;
      width: 100%;
    }
  }
  &__block {
    @include phones {
      margin-bottom: 3.5rem;
    }
  }

  &__form-edu {
    @include phones {
      margin-bottom: 0.8rem;
    }
  }
  &__formats {
    @include phones {
      flex-direction: column;
    }
  }
  &__item {
    @include phones {
      margin-bottom: 0.4rem;
      margin-right: 0;
    }
  }

  &__line-grey {
    @include phones {
      display: none;
      margin-right: 0;
    }
  }
}
