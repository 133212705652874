.univer {
  &__title {
    @include tablets {
      font-size: 2rem;
      margin-bottom: 2.8rem;
    }
  }
  &__item {
    @include phones {
      width: 49%;
    }
  }
  &__icon {
    @include tablets {
      height: 9.7rem;
      padding: 1.2rem;
    }
  }

  &__desc {
    @include tablets {
      font-size: 1.2rem;
    }
  }
}
