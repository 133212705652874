.lang-foreigner-desc {
    @include phones {
        margin-bottom: 6rem;
    }

    &__block {
        @include phones {
            margin-bottom: 3rem;
        }
    }

    &__top {
        @include phones {
            font-size: 4rem;
        }
    }

    &__bottom {
        @include phones {
            font-size: 3rem;
        }
    }
}