.links {
  @include desktop {
    margin-bottom: 100px;
    &-phone {
      left: 15px;
    }
    &__box {
      padding: 0 35px;
      &-info {
        padding: 30px 0;
        h3 {
          font-size: 38px;
        }
        p {
          font-size: 18px;
          margin-bottom: 30px;
        }
      }
    }
  }
  @include tablets {
    margin-bottom: 60px;
    &-phone {
      display: none;
    }
    &__box {
      padding: 0 20px;
      &-info {
        max-width: 100%;
        padding: 30px 0;
      }
    }
  }
  @include phones {
    margin-bottom: 40px;
    &__box {
      &-info {
        h3 {
          font-size: 30px;
        }
        p {
          font-size: 16px;
          margin-bottom: 20px;
        }
        div {
          justify-content: space-between;
          a {
            max-width: 100%;
            margin-right: 0;
            img {
              max-width: 90%;
            }
          }
        }
      }
    }
  }
}
