.news {
  @include tablets {
    margin-bottom: -2.3rem;
  }
  &__title {
    @include tablets {
      font-size: 3rem;
      line-height: 1.13;
      font-weight: 400;
    }
  }

  &__list {
    @include phones {
      flex-wrap: nowrap;
      width: 100vw;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
    }
  }

  &__item {
    @include tablets {
      width: 22.2rem;
      height: 36rem;
    }

    @include phones {
      min-width: 21.6rem;
      height: 36rem;
      margin-right: 1.2rem;
      margin-bottom: 0;
      scroll-snap-align: start;
    }
  }

  &__more {
    @include phones {
      display: none;
    }
  }
}
