.foot {
  @include tablets {
    padding: 6.2rem 0rem 4rem;
  }

  @include phones {
    padding: 6.2rem 0rem 8rem;
  }

  &__up {
    @include tablets {
      margin-bottom: 2.8rem;
      flex-direction: column;
      justify-content: normal;
    }

    @include phones {
      margin-bottom: 1.8rem;
    }
  }

  &__list {

    @include tablets {
      flex-direction: column;
      align-items: center;
    }
  }
  &__item {
    @include tablets {
      font-size: 1.4rem;
    }

    @include tablets {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }

  &__nav {
    @include tablets {
      width: 96%;
    }
  }

  &__down {
    @include tablets {
      padding: 0 1.5rem 0 1.5rem;
      align-items: center;
    }

    @include tablets {
      align-items: center;
      flex-direction: column-reverse;
    }
  }

  &__copy {
    @include tablets {
      line-height: 1.2;
    }
  }
  &__socials {
    @include tablets {
      flex-direction: column;
      margin-bottom: 3.2rem;
    }
    &-box {
      @include tablets {
        align-items: center;
        margin: 0;
        margin-top: 2rem;
      }
    }
  }

  &__name {
    @include tablets {
      margin-right: 0.5rem;
    }

    @include phones {
      margin-right: 0;
      margin-bottom: 0.8rem;
    }
  }

  &__logo {
    @include tablets {
      margin-bottom: 2rem;
    }

    @include phones {
      margin-bottom: 3rem;
    }
  }

  &__btn {
    @include phones {
      display: flex;
    }
  }

}
