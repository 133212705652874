.events {

    @include tablets {
        padding-top: 4rem;
    }

    &__title {

        @include tablets {
            margin-bottom: 2.4rem;
        }

        @include tablets {
            font-size: 3.8rem;
        }
    }

    &__list {

        @include phones {
            flex-wrap: nowrap;
            width: 100vw;
            overflow-x: auto;
            scroll-snap-type: x mandatory;
        }
    }

    &__item {

        @include tablets {
            width: 22.2rem;
        }
    
        @include phones {
            display: flex;
            min-width: 21.6rem;
            margin-right: 1.2rem;
            margin-bottom: 0;
            scroll-snap-align: start;
        }
    }

    &__more {

        @include phones {
            display: none;
        }
    }
}