.hello {
  @include desktop {
    padding-top: 4rem;
  }

  @include tablets {
    padding: 0;
  }

  &::before {
    @include tablets {
      bottom: 34rem;
      background-size: 62%;
      width: 100%;
    }

    @include phones {
      background: url(/img/hello/hello-mob.png) bottom center/80% no-repeat;
      bottom: 0rem;
      z-index: 0;
    }
  }

  &__title {
    @include desktop {
      font-size: 2rem;
    }

    @include tablets {
      font-size: 2rem;
      margin: 5.8rem 0 2.6rem;
    }

    @include phones {
      margin: 1rem 0 0.7rem;
      width: 70%;
    }
  }

  &__subtitle {

    @include desktop {
      font-size: 6.4rem;
      margin-bottom: 2rem;
    }

    @include tablets {
      font-size: 5.6rem;
      width: 41%;
      margin-bottom: 5rem;
      white-space: normal;
    }

    @include phones {
      width: 95%;
      margin-bottom: 25rem;
      letter-spacing: -0.01rem;
      display: none;
    }

    &_mob {
        
        @include phones {
            display: block;
        } 
    }
  }

  .form {
    @include phones {
      display: none;
    } 
  }

}
