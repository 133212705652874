.why {

  @include tablets {

    padding-bottom: 5rem;

    &__title {
      font-size: 3rem;
      margin-bottom: 3rem;
    }

    &__list {
      flex-direction: column;
      gap: 2rem 0;
    }

    &__item {
      position: relative;
      flex: 0 0 100%;
      padding-left: 8.7rem;

      &:before {
        position: absolute;
        top: 0; 
        left: 0;
        content: '';
        width: 71px;
        height: 71px;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
      }

      &_1:before {background-image: url(/img/nav/students/employment/blocks/why/icon1.svg);}
      &_2:before {background-image: url(/img/nav/students/employment/blocks/why/icon2.svg);}
      &_3:before {background-image: url(/img/nav/students/employment/blocks/why/icon3.svg);}
      &_4:before {background-image: url(/img/nav/students/employment/blocks/why/icon4.svg);}
      &_5:before {background-image: url(/img/nav/students/employment/blocks/why/icon5.svg);}

      &-title {
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }

      &-text {
        font-size: 1.4rem;
      }
    }
  }


  @include phones {

    padding-bottom: 6rem;

    &__title {
      font-size: 3rem;
      margin-bottom: 3rem;
    }

    
  }

}
