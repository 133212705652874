.become {
  &__content {
    @include tablets {
      flex-direction: column;
    }
  }

  &__left {
    @include tablets {
      width: 100%;
      margin-bottom: 2.8rem;
    }
  }

  &__right {
    @include phones {
      width: 100vw;
      margin-left: calc(-50vw + 50%);
    }
  }
  &__title {
    @include tablets {
      font-size: 3rem;
    }
  }

  &__text {
    @include tablets {
      white-space: normal;
      width: 100%;
    }

    @include phones {
      font-size: 1.8rem;
      width: 85%;
    }
  }

  &__list {
    @include phones {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      flex-wrap: nowrap;
    }
  }

  &__item {
    @include tablets {
      width: 49%;
    }

    @include phones {
      min-width: 27.2rem;
      margin-right: 1.2rem;
      margin-bottom: 0;
      scroll-snap-align: start;
      scroll-margin-left: 1.5rem;

      &:first-child {
        margin-left: 1.5rem;
      }
    }

    &:last-child {
      min-height: 16rem;

      @include tablets {
        min-height: 13.2rem;
      }

      @include phones {
        margin-right: 0;
      }
    }
  }

  &__banner {
    &::before {
      @include tablets {
        background-size: cover;
        background-position: right -30rem center;
      }
      @include phones {
        background-position: left bottom;
      }
    }

    &::after {
      @include tablets {
        top: -1rem;
        bottom: -1rem;
      }

      @include phones {
        top: 0;
        bottom: 0;
        background-position: top right -13rem;
        background-size: 30rem;
      }
    }

    @include phones {
      width: 100vw;
      margin-left: calc(-50vw + 50%);
      padding: 4rem 1.5rem 5rem 1.5rem;
    }
  }

  &__banner-title {
    @include phones {
      display: none;
      font-size: 2.8rem;
      margin-bottom: 8.2rem;
    }

    &_phones {
      @include phones {
        display: block;
      }
    }
  }

  &__banner-desc {
    @include phones {
      font-size: 1.8rem;
      width: 20rem;
      margin-bottom: 4.6rem;
    }
  }

  &__banner-button {
    @include phones {
      width: 100%;
    }
  }
}
