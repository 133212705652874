.popup-banner {
    @include desktop {
        height: 80vh;
    }
    @include phones {
        height: 70vh;
        padding: 5rem 0 3rem;
    }
	&__button{
        @include tablets {
            top: 2rem;
            right: 2rem;
            width: 2rem;
        }
        @include phones {
            top: 2rem;
            right: 2rem;
            width: 1.2rem;
        }
	}
    &__top{
        @include desktop {
            margin-bottom: 6rem;
        }
        @include tablets {
            margin-bottom: 5rem;
        }
        &-title{
            @include desktop {
                font-size: 7rem;
            }
            @include tablets {
                font-size: 5.8rem;
                font-weight: 500;
            }
            @include phones {
                font-size: 4rem;
            }
            br{
                @include tablets {
                    display: block;
                }
            }
            span{
                @include tablets {
                    color: #212121;
                }
            }
        }
    }

    &__center{
        @include desktop {
            height: 28rem;
        }
        @include tablets {           
            height: 24rem;
        }
        @include phones {
            width: 30rem;
        }
        
        &-heart{
            @include desktop {
                top: -6.5rem;
                left: 11rem;
                width: 12.5rem;
            }
            @include tablets { 
                display: none;
            }
        }
        &-mbHeart{
            @include tablets { 
                display: block;
                z-index: 4;
                position: absolute;
                top: 0rem;
                left: 18rem;
                width: 10rem;
                transform: rotate(26deg);
            }
            @include phones {
                top: 2rem;
                left: 12.5rem;
                width: 7.5rem;
            }
        }
        &-hand{
            @include desktop {
                top: -8rem;
                left: 27.2rem;
                width: 26rem;
            }
            @include tablets { 
                top: -5rem;
                left: 15rem;
                width: 22rem;
            }
            @include phones {
                top: -2.2rem;
                left: 9rem;
                width: 17rem;
            }
        }
        &-date{
            @include desktop {
                top: 0;
                left: 7.2rem;
                font: 5.5rem "PF Din Text Comp Pro Medium", sans-serif;
                padding: 1rem 3.6rem;
                width: unset;
            }
            @include tablets { 
                top: 0;
                left: 5rem;
                border-radius: 1.2rem;
                font: 4rem "PF Din Text Comp Pro Medium", sans-serif;
                padding: 1rem 3.6rem;
                width: unset;
            }
            @include phones {
                border-radius: 1rem;
                font: 2.5rem "PF Din Text Comp Pro Medium", sans-serif;
                padding: 1.3rem 3.6rem;
                top: 1.5rem;
                left: 2rem;
            }
        }
        &-time{
            @include desktop {
                top: 3.4rem;
                right: 7.6rem;
                font: 5.5rem "PF Din Text Comp Pro Medium", sans-serif;
                padding: 1rem 3.6rem;
            }
            @include tablets { 
                top: 6rem;
                right: 18.8rem;
                border-radius: 1.2rem;
                font: 4rem "PF Din Text Comp Pro Medium", sans-serif;
                padding: 1rem 3.6rem;
                transform: rotate(21deg);
            }
            @include phones {
                top: 6.5rem;
                right: 5.5rem;
                border-radius: 1rem;
                font: 2.5rem "PF Din Text Comp Pro Medium", sans-serif;
                padding: 1.3rem 3.6rem;
            }
        }
    }

    
    &__bottom{
        @include desktop {
            gap: .7rem;
        }
        &-text{
            @include desktop {
                font-size: 1.8rem;
            }
            @include tablets {
                font-size: 1.6rem;
            }
            @include phones {
                position: absolute;
                text-align: start;
                top: -4rem;
                color: #000;
                left: -1rem;
                font-size: 1.4rem;
            }
        }
        &-button{
            @include desktop {
                font-size: 2.8rem;
                padding: 1.2rem 3.4rem;
                box-shadow: .6rem .6rem 0px 0px #9900B2;
            }
            @include tablets {
                font-size: 2.5rem;
                padding: 1.2rem 3.4rem;
                box-shadow: .5rem .5rem 0px 0px #9900B2;
            }
            @include phones {
                font-size: 2.2rem;
                padding: 1rem 3.2rem;
                box-shadow: .4rem .4rem 0px 0px #9900B2;
            }
        }
    }


    &__img{
        &-book{
            @include desktop {
                top: 15rem;
                left: -5.6rem;
                width: 18.5rem;
            }
            @include tablets { 
                top: 10rem;
                left: -5.5rem;
                width: 16.5rem;
            }
            @include phones {
                top: 7.2rem;
                left: -4.2rem;
                width: 13rem;
            }
        }
        &-girl{
            @include desktop {
                bottom: 0;
                width: 32rem;
                left: 0;
            }
            @include tablets { 
                display: none;
            }
        }
        &-man{
            @include desktop {
                bottom: -23.2rem;
                right: -4.6rem;
                width: 39.5rem;
            }
            @include tablets { 
                bottom: -1.5rem;
                right: -14.5rem;
                width: 35.4rem;
            }
            @include phones {
                bottom: -6rem;
                right: -11rem;
                width: 27.4rem;
            }
        }
        &-pencil{
            @include desktop {
                top: 12.5rem;
                width: 13.5rem;
                right: 3rem;
            }
            @include tablets { 
                top: 20rem;
                right: 10rem;
                width: 12rem;
            }
            @include phones {
                top: 14rem;
                right: 8rem;
                width: 7rem;
            }
        }
    }

    @include tablets {

    }
    @include phones {
        
    }
}