.information {
  @include tablets {
    padding-bottom: 5rem;
  }

  @include phones {
    padding-bottom: 6rem;
  }

  &__body {
    @include tablets {
      width: 100%;
    }
  }

  &__title {
    @include phones {
      font-size: 3rem;
      margin-bottom: 3rem;
    }
  }

  &__subtitle-mobile {
    @include phones {
      display: block;
      font-size: 2rem;
      margin-bottom: 1.2rem;
    }
  }

  &__switch {
    @include tablets {
      margin-bottom: 0;
    }
  }

  &__list-link {
    @include phones {
      overflow-y: auto;
      scroll-snap-type: x mandatory;
    }
  }

  &__item-link {
    @include phones {
      scroll-snap-align: start;
      border: 0.2rem solid #a0a2a699;
      border-radius: 0.6rem;
      color: #6e7175;
      font-size: 1.6rem;
      line-height: 1.37;
      padding: 0.4rem 1rem 0.5rem;
      margin-right: 1.2rem;
    }

    &_active {
      @include phones {
        border-bottom: none;
        border: 0.2rem solid #43c375;
        border-radius: 0.6rem;
        color: #212121;
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
  }

  &__desc {
    @include phones {
      margin-bottom: 2.4rem;
    }
  }

  &__desc-text-bold {
    @include phones {
      line-height: 1.39;
      margin-bottom: 1.2rem;
    }
  }

  &__desc-text {
    @include tablets {
      font-size: 1.8rem;
      line-height: 1.4;
    }

    @include phones {
      font-size: 1.4rem;
      line-height: 1.57;
    }
  }

  &__step-title {
    @include phones {
      font-size: 1.8rem;
    }
    &_three {
      @include phones {
        line-height: 1.39;
      }
    }
  }

  &__step-desc {
    @include phones {
      font-size: 1.4rem;
      line-height: 1.57;
      margin-bottom: 2.8rem;
    }
  }

  &__step-subitem {

    @include phones {
      font-size: 1.4rem;
      line-height: 1.57;
      margin-bottom: 0;
      margin-left: 0.9rem;
    }

    &_two {
      @include phones {
        margin-left: 0.4rem;
      }

      @include phones {
        line-height: 1.57;
      }
    }

    &_five {
      @include phones {
        line-height: 1.57;
      }
    }
  }

  &__text-hell {
    @include phones {
      line-height: 1.57;
    }
  }

  &__text-four {
    @include phones {
      font-size: 1.4rem;
      line-height: 1.57;
    }
  }

  &__step-sublist_numbers {
    @include phones {
      flex-wrap: nowrap;
      flex-direction: column;
      margin-left: 1rem;
    }
  }

  &__numbers {
    @include tablets {
      width: 32%;
    }

    @include phones {
      flex-direction: row;
      width: 100%;
      margin-bottom: 1.2rem;
    }

    &-top {
      @include phones {
        margin-bottom: 2.7rem;
        margin-right: 2rem;
        display: flex;
        align-items: baseline;
      }
      &_mobile {
        @include phones {
          font-size: 4.2rem;
        }
      }
      &-mobile {
        @include phones {
          font-size: 2.2rem;
          white-space: nowrap;
        }
      }
    }

    &-bottom {
      @include phones {
        text-align: inherit;
      }
    }
  }

  &__question {
    @include tablets {
      display: none;
    }
  }
}
