.college {
  @include tablets {
    margin-bottom: -2rem;
  }
  &__title {
    @include tablets {
      font-size: 2rem;
      margin-bottom: 2.8rem;
    }
  }
  &__list {
    @include phones {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }
  &__item {
    @include tablets {
      font-size: 1.4rem;
    }

    @include phones {
      width: 100%;
    }
  }
}
