.cooperations {
  @include tablets {
    padding-top: 4rem;
  }
  &__title {
    @include tablets {
      margin-bottom: 2.4rem;
    }

    @include tablets {
      font-size: 3.8rem;
    }
  }
  &__subtitle {
    @include tablets {
      font-size: 3rem;
      margin-bottom: 3.8rem;
    }
  }
  &__list {
    @include phones {
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      flex-wrap: nowrap;
      width: 100vw;
    }
  }

  &__item {
    @include tablets {
      width: 49%;
    }

    @include phones {
      scroll-snap-align: start;
      min-width: 27.2rem;
      margin-right: 1.2rem;
    }

    &:last-child {
      @include tablets {
        flex-direction: row;
        width: 100%;
        align-items: center;
        padding: 3rem 4rem 3rem 2.4rem;
      }

      @include phones {
        display: none;
      }
    }

    &:nth-child(7) {
      @include tablets {
        margin-bottom: 2rem;
      }
    }

    &:nth-child(8) {
      @include tablets {
        margin-bottom: 2rem;
      }
    }
  }

  &__mobile {
    @include phones {
      display: flex;
      display: flex;
      background: url(/img/nav/partners/blocks/form-partners/form-heptagon.png)
        #fdce4c left/90% no-repeat;
      width: 100%;
      height: 7.3rem;
      align-items: center;
      justify-content: center;
      border-radius: 2rem;
      padding: 1.6rem 2rem;
    }
  }

  &__circle {
    &_white {
      @include tablets {
        margin-bottom: 0;
      }
    }
  }
  &__button {
    @include tablets {
      width: 30rem;
    }
  }
}
