.agents {
  &::before {
    @include tablets {
      background-size: 50%;
    }

    @include phones {
      background-position: center top;
      background-size: 85%;
    }
  }
  &__title {
    @include tablets {
      margin-bottom: 2.4rem;
      font-size: 3rem;
    }

    @include phones {
      padding-top: 25rem;
      letter-spacing: 0.1rem;
      text-align: center;
    }
  }
  &__desc {
    @include tablets {
      font-size: 1.8rem;
    }

    @include phones {
      width: 100%;
    }

    &:last-child {
      @include phones {
        margin-bottom: 2rem;
      }
    }
  }

  &__list {
    @include phones {
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      flex-wrap: nowrap;
      width: 100vw;
      margin-bottom: 8.5rem;
    }
  }

  &__item {
    @include tablets {
      width: 49%;
      min-height: 11rem;
    }

    @include phones {
      scroll-snap-align: start;
      min-width: 27.2rem;
      margin-right: 2.1rem;
      margin-bottom: 0;
    }
  }
}
