.neuronet {
    
    &__inner {

        @include desktop {
            max-width: 70rem;
            padding: 15rem 4rem 5rem 4rem;
            border-radius: 4rem;

            &:before {
                top: -7.2rem;
                width: 18rem;
                height: 19.9rem;
            }
        }

        @include tablets {
            max-width: 32rem;
            padding: 10.6rem 3rem 18rem 3rem;
            border-radius: 4rem;

            &:before {
                top: auto;
                bottom: 0;
                width: 14.9rem;
                height: 16.4rem;
            }
        }
    }

    &__title {

        @include desktop {
            padding: 0 1rem;
            border-radius: 1.3rem;
            font-size: 4.4rem;
            margin-bottom: 2rem;
        }
        
        @include tablets {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            border-radius: 0;
            background: none;
            font-size: 3.1rem;
            margin-bottom: 1.2rem;

            SPAN {
                display: block;
                padding: 0 1rem;
                border-radius: .6rem;
                background: #fdce4c;
            }
        }
    }

    &__text {

        @include desktop {
            font-size: 4.4rem;
            margin-bottom: 2rem;
        }
        
        @include tablets {
            font-size: 3.1rem;
            margin-bottom: 4rem;
        }
    }

    &__button {

        @include tablets {
            width: 14.6rem;
            height: 3.5rem;
            padding: 0 1.6rem;
            border-radius: 1.4rem;
            font-size: 1.2rem;
            margin-right: 3.5rem;
        
            &:after {
                bottom: -0.1rem;
                right: -3.5rem;
                width: 3.5rem;
                border-radius: 1.4rem;
                background-size: .5rem .9rem;
            }
        }
    }
}
