.menu {
  @include desktop {
    right: 4%;
  }

  @include phones {
    right: 0;
  }

  &__fix {
    @include phones {
      right: -27rem;
      border: none;
      opacity: unset;
      border-radius: 2rem 0 0 2rem;
    }
  }

  &__btn {
    &:before {
      @include phones {
        display: none;
      }
    }

    &:after {
      @include phones {
        display: none;
      }
    }

    @include phones {
      width: 1.5rem;
      height: 24.4rem;
      border: none;
      border-radius: 2rem 0 0 2rem;
      background: url(/img/fix-menu/btn-bg.svg) center center / 0.5rem no-repeat,
        #fff;
    }
  }
}

.active-menu-fix {
  @include phones {
    right: 0;
  }
}
