.lang-questions {
  @include tablets {
    margin-bottom: -2rem;
  }

  &__title {
    @include phones {
      font-size: 4rem;
      margin-bottom: 6rem;
    }
  }
}
