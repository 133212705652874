.paid {
  &__left {
    @include tablets {
      width: 100%;
    }
  }

  &__title {
    @include phones {
      font: 400 3rem "PF Din Text Comp Pro";
    }
  }

  &__subtitle {
    @include phones {
      font-size: 1.4rem;
    }
  }

  &__btn {
    @include phones {
      width: 100%;
      text-align: center;
      margin-left: 0;
    }
    &::before {
      @include phones {
        -webkit-mask: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.75 8.33301V5.33301H8.25V8.33301H6L9 11.333L12 8.33301H9.75Z' fill='%23F96605'/%3E%3Crect x='4' y='13' width='10' height='1' fill='%23F96605'/%3E%3C/svg%3E%0A")
          center right 2rem / 2rem no-repeat;
        width: 50%;
      }
    }
  }

  &__right {
    @include tablets {
      display: none;
    }
  }
}
