.spa {
  @include tablets {
    padding-top: 5rem;
  }

  @include phones {
    padding-top: 3.5rem;
  }

  &__title {
    @include tablets {
      margin-bottom: 2.5rem;
    }

    @include phones {
      font-size: 3.8rem;
    }
  }

  &__subtitle {
    @include tablets {
      font-size: 2rem;
      width: 59%;
      margin-bottom: 2rem;
    }

    @include phones {
      width: 100%;
      line-height: 1.36;
    }
  }

  &__left {
    @include tablets {
      width: 100%;
    }
  }

  &__right {
    @include tablets {
      display: none;
    }
  }

  &__wish {
    @include tablets {
      padding: 4.3rem 3rem 4rem 4rem;
    }

    @include phones {
      padding: 3.8rem 3rem 4rem 4rem;
      margin-bottom: 2rem;
      background-position: top 15.5rem right -4rem;
      background-size: calc(100vh - 115%);
    }
    &-two {
      @include tablets {
        margin-bottom: 3.6rem;
      }

      @include phones {
        font-size: 1.6rem;
        line-height: 1.37;
        margin-bottom: 2.4rem;
      }
    }

    &-three {
      @include tablets {
        font-size: 1.6rem;
        margin-bottom: 0;
      }

      @include phones {
        font-size: 1.4rem;
        line-height: 1.57;
        width: 95%;
      }
    }

    &-for {
      @include tablets {
        font-size: 1.6rem;
      }

      @include phones {
        font-size: 1.4rem;
        line-height: 1.57;
      }
    }
  }
}
