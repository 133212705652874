.form-partners {
  @include tablets {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    padding: 4rem 3.5rem 3rem;
    flex-direction: row;
  }

  @include phones {
    flex-direction: column;
  }

  &__title {
    @include tablets {
      flex: 1 0 35%;
    }
  }

  &__group {
    @include tablets {
      flex-direction: column;
      margin-bottom: 1.2rem;
    }
  }

  &__line {
    @include tablets {
      margin-bottom: 1.2rem;
    }
  }

  &__block {
    @include tablets {
      margin-bottom: 1.2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-text {
      @include tablets {
        margin-bottom: 1.2rem;
      }
    }
  }

  &__input-big {
    @include tablets {
      height: 9rem;
    }
  }
}
