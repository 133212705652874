.event {

    @include tablets {
        padding-top: 4rem;
    }

    &__title {

        @include tablets {
            font-size: 3.8rem;
            margin-bottom: 2.4rem;
        }

        @include phones {
            font-size: 3rem;
            margin-bottom: 3rem;
        }
    }

    &__description {

        @include tablets {
            font-size: 2rem;
            line-height: 1.6;
            padding-left: 2.6rem;
            margin-bottom: 6rem;
        }
    }
}