.lang-edu-foreigner {
    @include phones {
        padding: 3rem 0 3rem;
        margin-bottom: 6rem;
    }
  &__title {
    @include phones {
      font-size: 3rem;
      margin-bottom: 3rem;
    }
  }

  &__desc {
    @include phones {
      font-size: 1.4rem;
      width: 100%;
    }
  }
}

.change-lang {
  @include phones {
    padding: .5rem 1rem;
  }
}