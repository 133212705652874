.employment {
  &__body {
    @include phones {
      padding-top: 4rem;
    }
  }

  &__left {
    @include tablets {
      width: 100%;
    }
  }

  &__title {
    @include tablets {
      font-family: "PF Din Text Comp Pro";
      font-size: 4.8rem;
      letter-spacing: 0.1rem;
      margin-bottom: 3rem;
    }

    @include phones {
      font-size: 3rem;
      margin-bottom: 2.4rem;
    }
  }

  &__subtitle {
    @include tablets {
      font-size: 2rem;
      margin-bottom: 1.7rem;
    }

    @include phones {
      font-size: 1.8rem;
      margin-bottom: 1.8rem;
    }
  }

  &__sub-desc {
    @include tablets {
      font-size: 1.8rem;
      line-height: 1.4;
    }

    @include phones {
      font-size: 1.4rem;
      line-height: 1.57;
      width: 95%;
    }
  }

  &__line {
    @include tablets {
      margin-right: 5.6rem;
    }

    @include phones {
      margin-right: 5.3rem;
    }
  }

  &__text-up {
    @include tablets {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    @include phones {
      font-size: 1.6rem;
      line-height: 1.43;
      margin-bottom: 1.6rem;
    }
  }

  &__text-down {
    @include tablets {
      font-size: 1.8rem;
      line-height: 1.6;
      width: 90%;
    }

    @include phones {
      font-size: 1.4rem;
      width: 100%;
    }
  }

  &__right {
    @include tablets {
      display: none;
    }
  }
}
