.faculties-hotel {
  @include phones {
    height: 71rem;
  }

  &::before {
    @include tablets {
      background-size: 50%;
      bottom: 0;
      right: -10rem;
    }

    @include phones {
      background-size: 60%;
      background-position: bottom right 0;
      right: -7rem;
      bottom: 15rem;
    }
  }

  &__name {
    @include tablets {
      font-size: 4rem;
      margin-bottom: 1.6rem;
    }

    @include phones {
      font-size: 3rem;
      margin-bottom: 3.2rem;
    }
  }
}
