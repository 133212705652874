.admission {

  @include tablets {

    &_main {
      padding-top: 4rem;
    }
  }

  @include phones {
    margin-bottom: 6rem;
  }

  &__title {
    @include phones {
      font-size: 3rem;
    }
  }
  
  &__body {
    @include tablets {
      width: 100%;
    }
  }

  &__edu {
    @include phones {
      font-size: 1.6rem;
    }
  }

  &__item {
    @include phones {
      flex-direction: column;

      &-title {
        flex-direction: column;
      }
    }
    &-subitem {
      @include phones {
        flex-direction: column;
      }
    }
  }

  &__desc {
    @include phones {
      padding-right: 0;
      margin-bottom: 1rem;
    }
  }

  &__btn {
    @include phones {
      width: 100%;
    }
  }

  &__button {
    @include phones {
      width: 100%;
      justify-content: center;
    }

    &-text {
      @include phones {
        margin-right: 0.6rem;
      }
    }
  }
}
