.about-academy {
  &__container {
    @include phones {
      flex-direction: column;
    }
  }

  &__title {
    @include tablets {
      font-size: 3.8rem;
      padding-bottom: 3.4rem;
    }
  }
  &__left {
    @include tablets {
      flex: 1 0 4%;
    }

    @include phones {
      flex: auto;
      margin-bottom: 4.1rem;
    }
  }

  &__right {
    @include tablets {
      height: 26.4rem;
    }

    @include phones {
      height: 30.6rem;
      flex: auto;
    }
  }

  &__line {
    @include tablets {
      margin-right: 5.6rem;
    }

    @include phones {
      margin-right: 2.6rem;
    }
  }

  &__text {
    @include tablets {
      font-size: 2rem;
    }

    @include phones {
      font-size: 1.8rem;
    }
  }
}
