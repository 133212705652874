.menu {
  &_intelligence {
    display: none;

    @include tablets {
      display: block;
    }

    @include phones {
      bottom: calc(100% - 5rem);
    }
  }

  &__btn {
    &_intelligence {
      @include phones {
        height: 60rem;
      }
    }
  }
}
