.entrant {
  @include phones {
    margin-bottom: 0;
  }

  &__body {
    @include phones {
      flex-direction: column;
    }
  }

  &__title {
    @include tablets {
      margin-bottom: 4rem;
    }

    @include phones {
      font-size: 3.8rem;
      line-height: 1.2;
    }
  }

  &__subtitle {
    @include tablets {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }

  &__desc {
    @include tablets {
      font-size: 1.8rem;
      width: 36rem;
    }

    @include phones {
      width: 95%;
      font-size: 1.6rem;
    }
  }

  &__left {
    @include tablets {
      width: 48%;
      padding-top: 6.1rem;
      z-index: 100;
    }

    @include phones {
      width: 100%;
      margin-bottom: 0;
      padding-top: 3.2rem;
    }
  }

  &__right {
    @include tablets {
      width: 52%;
    }

    @include phones {
      width: 100%;
      height: 30rem;
    }

    &:before {
      @include phones {
        background: url("/img/nav/applicants/blocks/entrant/entrant-mob.png")
          center bottom/contain no-repeat;
        left: 0;
      }
    }
  }

  .form {
    @include phones {
      display: none;
    } 
  }
}
