.exams {
  &__tile {
    @include tablets {
      font-size: 3rem;
    }
  }

  &__list {
    @include phones {
      flex-direction: column;
      align-items: center;
    }
  }

  &__item {
    @include tablets {
      min-height: 10rem;
      padding: 2rem;
    }

    @include phones {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1.2rem;
    }
  }

  &__profile {
    @include tablets {
      font-size: 1.8rem;
    }
  }
  &__profile-subject {
    @include tablets {
      font-weight: 400;
    }
  }
}
